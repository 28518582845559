const HeroBox = () => {
	const block = document.querySelector(".hero-box");
	if (!block) {
		return;
	}
	const bg = block.querySelector(".hero-box__bg-decors");
	const decorEls = block.querySelectorAll(".hero-box__bg-decor-el");

	decorEls.forEach((el) => {
		const parent = el.closest(".hero-box__bg-decor");
		parent.style.width = `${el.clientWidth}px`;
		parent.style.height = `${el.clientHeight}px`;
	});

	const parallaxInstance = new Parallax(bg, {
		selector: ".hero-box__bg-decor-el"
	});
};

export default HeroBox;
