import { gsap } from "gsap";

const GlobeInfo = () => {
	const block = document.querySelector(".globe-info");
	if (!block) {
		return;
	}
	const tickerWrap = block.querySelector(".globe-info__ticker-wrap");
	const tickerGroup = block.querySelector(".globe-info__ticker-group");
	tickerWrap.append(tickerGroup.cloneNode(true));

	gsap.to(tickerWrap, { x: -tickerGroup.offsetWidth, repeat: -1, duration: 120, ease: 'none' });
};

export default GlobeInfo;
