const PageMenu = () => {
	const block = document.querySelector(".page-menu");
	if (!block) {
		return;
	}
	const menuToggle = block.querySelectorAll(".page-menu__title");

	menuToggle.forEach((control) => {
		control.addEventListener("click", (e) => {
			e.target.closest(".page-menu__group").classList.toggle("is-open");
		});
	});
};

export default PageMenu;
