import { gsap } from "gsap";

const RatingInfo = () => {
	const block = document.querySelector(".rating-info");
	if (!block) {
		return;
	}
	document.querySelectorAll(".rating-info").forEach((element) => {
		const items = element.querySelectorAll(".rating-info__item");
		const title = element.querySelector(".rating-info__title");
		const image = element.querySelector(".rating-info__image");
		const titleSplit = Splitting({ target: title, by: "lines" });
		titleSplit[0].lines.forEach((line) => {
			Splitting({ target: line, by: "chars" });
		});

		const TLRatingInfo = gsap.timeline({
			scrollTrigger: {
				trigger: element,
				start: 'top 80%',
				end: `+=${window.innerHeight / 3}`,
				scrub: true
			}
		});

		TLRatingInfo.to(title.querySelectorAll(".char"), { y: "0%", opacity: 1, stagger: { each: 0.02 } });
		TLRatingInfo.to(items, { y: "0%", opacity: 1, stagger: { each: 0.4 } }, 0.3);
		TLRatingInfo.fromTo(image, { autoAlpha: 0, y: 40 }, { autoAlpha: 1, y: 0, duration: 1 }, 0.2);
	});
};

export default RatingInfo;
