const PageLayout = () => {
	const block = document.querySelector(".page-layout");
	if (!block) {
		return;
	}
	const navControls = block.querySelectorAll(".page-layout__nav-control");
	const menu = block.querySelector(".page-menu");
	const menuBack = menu.querySelector(".page-menu__back");
	const header = document.querySelector(".header");

	navControls.forEach((control) => {
		control.addEventListener("click", (e) => {
			menu.classList.add("is-open");
			header.classList.add("is-hidden");

			setTimeout(function () {
				menu.classList.add("is-show");
			}, 200);
		});
	});

	menuBack.addEventListener("click", (e) => {
		menu.classList.remove("is-show");
		header.classList.remove("is-hidden");
		setTimeout(function () {
			menu.classList.remove("is-open");	
		}, 200);
	});
};

export default PageLayout;
