import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const InfoTiles = () => {
	const block = document.querySelectorAll(".info-tiles");
	if (!block) {
		return;
	}
	block.forEach((element) => {
		gsap.registerPlugin(ScrollTrigger);
		const bg = element.querySelector(".info-tiles__inner-wrap");
		const title = element.querySelector(".info-tiles__title");
		const gridItems = element.querySelectorAll(".info-tiles__grid-item");
		const titleSplit = Splitting({ target: title, by: "lines" });
		titleSplit[0].lines.forEach((line) => {
			Splitting({ target: line, by: "chars" });
		});

		const TLInfoTiles = gsap.timeline({
			scrollTrigger: {
				trigger: element,
				start: 'top 80%',
				end: `+=${window.innerHeight * 0.6}`,
				scrub: true
			}
		});
		TLInfoTiles.fromTo(bg, { y: 100, scale: 0.8, borderRadius: 20 }, { y: 0, scale: 1, borderRadius: 40, duration: 2 }, 0);
		TLInfoTiles.to(title.querySelectorAll(".char"), { y: "0%", opacity: 1, stagger: { each: 0.02 } }, 0.4);
		TLInfoTiles.to(gridItems, { y: "0%", opacity: 1, stagger: { each: 0.4 } }, 1.5);
	});
};

export default InfoTiles;
