import Cookies from "js-cookie";

const CookiesPopup = () => {
	const block = document.querySelector(".cookies-popup");
	if (!block) {
		return;
	}
	const isSetGDPR = Cookies.get("softbookGDPR");
	const close = block.querySelector(".cookies-popup__close");

	if (!isSetGDPR) {
		block.classList.add("cookies-popup_show");
	}

	close.addEventListener("click", (e) => {
		Cookies.set("softbookGDPR", "true");
		block.classList.remove("cookies-popup_show");
	});
};

export default CookiesPopup;
