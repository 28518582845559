import Swiper from "swiper/bundle";

const ImagesSlider = () => {
	const block = document.querySelectorAll(".images-slider");
	if (!block) {
		return;
	}

	block.forEach((item) => {
		const pagination = item.querySelector(".images-slider .swiper-pagination");
		const arrowPrev = item.querySelector(".images-slider__arrow_prev");
		const arrowNext = item.querySelector(".images-slider__arrow_next");

		const swiper = new Swiper(item, {
			loop: true,
			slidesPerView: 1,
			pagination: {
				el: pagination,
				clickable: true,
				dynamicBullets: true
			},
			navigation: {
				nextEl: arrowNext,
				prevEl: arrowPrev
			}
		});
	});
};

export default ImagesSlider;
