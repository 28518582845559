import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

const Steps = () => {
	const block = document.querySelectorAll(".steps");
	if (!block) {
		return;
	}

	block.forEach((item) => {
		gsap.registerPlugin(ScrollTrigger);
		let mm = gsap.matchMedia();
		const panels = gsap.utils.toArray(".steps__item");
		
		panels.forEach((panel, index) => {
			const isLast = index === panels.length - 1;
	
			if (!isLast) {
				mm.add("(min-width: 768px)", () => {
					const tl = gsap.timeline({
						scrollTrigger:{
							trigger: panel,
							start: 'top 80px',
							end: `+=${panel.querySelector(".steps__card").offsetHeight}`,
							pinSpacing: false,
							pin: true,
							scrub: true
						}
					});
					tl.fromTo(
						panel,
						{ scale: 1, opacity: 1 },
						{ scale: 0.85, opacity: 0, duration: 0.9 }
					);
				});
			}
		});
	});
};

export default Steps;
