import Swiper from "swiper/bundle";

const ReviewsBox = () => {
	const block = document.querySelectorAll(".reviews-box");
	if (!block) {
		return;
	}

	block.forEach((item) => {
		const slider = item.querySelector(".reviews-box__list");
		const pagination = item.querySelector(".reviews-box__pagination");
		const arrowPrev = item.querySelector(".reviews-box__arrow_prev");
		const arrowNext = item.querySelector(".reviews-box__arrow_next");
		const reviewTextLength = item.querySelectorAll(".reviews-box__card-text");

		reviewTextLength.forEach(el => {
			if (el.textContent.length < 1000) {
				el.closest(".reviews-box__list-item").classList.add("reviews-box__list-item_md");
			}
		});

		const lightbox = GLightbox({
			selector: ".reviews-box__card-play"
		});

		const swiper = new Swiper(slider, {
      spaceBetween: 16,
			loop: true,
			pagination: {
				el: pagination,
				clickable: true
			},
			navigation: {
				nextEl: arrowNext,
				prevEl: arrowPrev
			},
			breakpoints: {
				0: {
					slidesPerView: 1,
					autoHeight: true
				},
				768: {
					centeredSlides: true,
					slidesPerView: "auto",
					autoHeight: false
				},
				1024: {
					centeredSlides: true,
					slidesPerView: "auto",
					autoHeight: false
				}
			}
		});
	});
};

export default ReviewsBox;
