const SystemInfo = () => {
	const block = document.querySelector(".system-info");
	if (!block) {
		return;
	}
	const bg = block.querySelector(".system-info__decors");
	const decorEls = block.querySelectorAll(".system-info__decor-el");

	decorEls.forEach((el) => {
		const parent = el.closest(".system-info__decor");
		parent.style.width = `${el.clientWidth}px`;
		parent.style.height = `${el.clientHeight}px`;
	});

	const parallaxInstance = new Parallax(bg, {
		selector: ".system-info__decor-el"
	});
};

export default SystemInfo;
