import { gsap, Power4 } from "gsap";

const MainIntro = () => {
	const block = document.querySelector(".main-intro");
	if (!block) {
		return;
	}
	const title = block.querySelector(".main-intro__title");
	const text = block.querySelector(".main-intro__text");
	const listItems = block.querySelectorAll(".main-intro__list-item");
	const widget = block.querySelector(".main-intro__widget");
	const media = block.querySelector(".main-intro__media");
	const bg = block.querySelector(".main-intro__bg-fill");
	const footer = block.querySelector(".main-intro__footer");
	const MainIntroTL = gsap.timeline();

	const titleSplit = Splitting({ target: title, by: "lines" });
	titleSplit[0].lines.forEach((line) => {
		Splitting({ target: line, by: "chars" });
	});

	if (bg) {
		MainIntroTL.fromTo(bg, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.6, ease: "none" });
	}
	if (title) {
		MainIntroTL.to(title.querySelectorAll(".char"), { y: "0%", opacity: 1, stagger: { each: 0.02 } });
	}
	if (media) {
		MainIntroTL.fromTo(media, { autoAlpha: 0, scale: 0.9 }, { autoAlpha: 1, scale: 1, duration: 0.5, ease: "none" }, 0.6);
	}
	if (text) {
		MainIntroTL.fromTo(text, { autoAlpha: 0, y: 30 }, { autoAlpha: 1, y: 0, duration: 1, ease: Power4.easeOut }, 0.6);
	}
	if (listItems) {
		MainIntroTL.to(listItems, { y: 0, opacity: 1, stagger: { each: 0.2 }, duration: 0.4 }, 0.8);
	}
	if (widget) {
		MainIntroTL.fromTo(widget, { autoAlpha: 0, y: 20 }, { autoAlpha: 1, y: 0, duration: 1, ease: Power4.easeOut }, 0.8);
	}
	if (footer) {
		MainIntroTL.fromTo(footer, { autoAlpha: 0, y: 20 }, { autoAlpha: 1, y: 0, duration: 1, ease: Power4.easeOut }, 0.8);
	}
};

export default MainIntro;
