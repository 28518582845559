import easyToggle from "easy-toggle-state";
import { gsap, Power1, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import BenefitsBox from "./blocks/BenefitsBox";
import InfoSlider from "./blocks/InfoSlider";
import ImagesSlider from "./blocks/ImagesSlider";
import GlobeInfo from "./blocks/GlobeInfo";
import ReviewsBox from "./blocks/ReviewsBox";
import TariffsBox from "./blocks/TariffsBox";
import PageLayout from "./blocks/PageLayout";
import PageMenu from "./blocks/PageMenu";
import CookiesPopup from "./blocks/CookiesPopup";
import Steps from "./blocks/Steps";
import MainIntro from "./blocks/MainIntro";
import InfoTiles from "./blocks/InfoTiles";
import RatingInfo from "./blocks/RatingInfo";
import HeroBox from "./blocks/HeroBox";
import SystemInfo from "./blocks/SystemInfo";

gsap.registerPlugin(ScrollTrigger);

const initScripts = () => {
	easyToggle();
	vhCheck();

	const handleScrollLink = (e) => {
		e.preventDefault();
		const target = document.querySelector(e.target.getAttribute("href"));
		const header = document.querySelector(".header");

		if (target) {
			gsap.to(window, {
				duration: 0.5,
				scrollTo: {
					y: target,
					offsetY: header.offsetHeight + 20
				}
			});
		}
	};

	document.querySelectorAll(".scroll-link").forEach((link) => {
		link.addEventListener("click", handleScrollLink);
	});

	document.querySelectorAll(".info-panel").forEach((panel) => {
		panel.querySelector(".info-panel__close").addEventListener("click", (e) => {
			panel.remove();
		});
	});

	// responsive table
	document.querySelectorAll(".rich-text table").forEach((table) => {
		const thead = table.querySelector("thead");
		const tbody = table.querySelector("tbody");
		const th = thead.querySelectorAll("th");

		tbody.querySelectorAll("tr").forEach((item) => {
			item.querySelectorAll("td").forEach((cell, index) => {
				cell.setAttribute("data-label", th[index].textContent);
			});
		});
	});

	document.querySelectorAll(".btn-default").forEach((button) => {
		button.innerHTML = `<span class="btn-label">${button.innerHTML}</span><span class="btn-border"></span>`;
		button.addEventListener("mousemove", (e) => {
			const { x, y } = button.getBoundingClientRect();

			button.style.setProperty("--x", e.clientX - x);
			button.style.setProperty("--y", e.clientY - y);
		});
	});

	document.querySelectorAll(".info-tiles__card").forEach((card) => {
		card.addEventListener("mousemove", (e) => {
			const bg = card.querySelector(".info-tiles__card-bg")
			const { x, y } = card.getBoundingClientRect();

			bg.style.setProperty("--x", e.clientX - x);
			bg.style.setProperty("--y", e.clientY - y);
		});
	});
};

initScripts();
BenefitsBox();
InfoSlider();
ImagesSlider();
GlobeInfo();
ReviewsBox();
TariffsBox();
PageLayout();
PageMenu();
CookiesPopup();
Steps();
MainIntro();
InfoTiles();
RatingInfo();
HeroBox();
SystemInfo();

const initAnimations = () => {
	const levitationEl = document.querySelectorAll(".levitation-el");
	if (!levitationEl) {
		return;
	}

	levitationEl.forEach((el) => {
		const offsetY = el.getAttribute("data-offset-y");
		const movePath = `${offsetY ? offsetY * -1 : -20}`;

		const TLInfoTiles = gsap.timeline({ repeat: -1 });

		TLInfoTiles.fromTo(el, { y: 0 }, { y: movePath, duration: 2, ease: Power1.easeInOut });
		TLInfoTiles.fromTo(el, { y: movePath }, { y: 0, duration: 2, ease: Power1.easeInOut });
	});

	// contact card hover glow
	document.querySelectorAll(".contact-card").forEach((el) => {
		const cursor = document.createElement("div");
		cursor.classList.add("contact-card__cursor");
		el.prepend(cursor);

		const moveCursor = (event) => {
			const elRect = el.getBoundingClientRect();
			const x = event.clientX - elRect.left;
			const y = event.clientY - elRect.top;

			el.querySelector(".contact-card__cursor").style.cssText = `
				left: ${x}px; 
				top: ${y}px;
			`;
		};

		const animationCursorShow = (e) => {
			document.addEventListener("mousemove", moveCursor, false);
		};

		const animationCursorHide = (e) => {
			document.removeEventListener("mousemove", moveCursor, false);
		};

		el.addEventListener("mouseenter", animationCursorShow, false);
		el.addEventListener("mouseleave", animationCursorHide, false);
	});
};
initAnimations();

const scrollAnimationHead = () => {
	const block = document.querySelectorAll(".scroll-animation-head");
	if (!block) {
		return;
	}

	block.forEach((element) => {
		const badge = element.querySelector(".scroll-animation-head__label");
		const title = element.querySelector(".scroll-animation-head__title");
		const text = element.querySelector(".scroll-animation-head__text");
		if (title) {
			const titleSplit = Splitting({ target: title, by: "lines" });
			titleSplit[0].lines.forEach((line) => {
				Splitting({ target: line, by: "chars" });
			});
		}

		const TLScrollAnimationHead = gsap.timeline({
			scrollTrigger: {
				trigger: element,
				start: 'top 82%',
				end: `+=${window.innerHeight / 3}`,
				scrub: true,
			}
		});
		if (badge) {
			TLScrollAnimationHead.fromTo(badge, { autoAlpha: 0, y: 30 }, { autoAlpha: 1, y: 0, duration: 1, ease: Power4.easeOut }, 0);
		}
		if (title) {
			TLScrollAnimationHead.to(title.querySelectorAll(".char"), { y: "0%", opacity: 1, stagger: { each: 0.02 } }, 0.2);
		}
		if (text) {
			TLScrollAnimationHead.fromTo(text, { autoAlpha: 0, y: 30 }, { autoAlpha: 1, y: 0, duration: 1, ease: Power4.easeOut }, 0.3);
		}
	});
};
scrollAnimationHead();

const mainMenu = () => {
	const block = document.querySelector(".header");
	if (!block) {
		return;
	}
	const toggle = block.querySelector(".header__toggle");
	const menuItemToggle = block.querySelectorAll(".header__menu-toggle");
	const menuItems = block.querySelectorAll(".header__menu-item");
	const back = block.querySelector(".header__back");

	toggle.addEventListener("click", (e) => {
		block.classList.remove("is-sub-open");
		menuItems.forEach((item) => {
			item.classList.remove("active");
		});
	});

	const backMenu = () => {
		menuItems.forEach((item) => {
			item.classList.remove("active");
		});

		setTimeout(() => {
			block.classList.remove("is-sub-open");
		}, 100);
	};

	const toggleMenu = (e) => {
		menuItems.forEach((item) => {
			item.classList.remove("active");
		});

		e.target.closest(".header__menu-item").classList.add("active");
		block.classList.add("is-sub-open");
	};

	menuItemToggle.forEach((item) => {
		item.addEventListener("click", toggleMenu);
	});
	back.addEventListener("click", backMenu);
};
mainMenu();
