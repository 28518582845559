import Swiper from "swiper/bundle";

const InfoSlider = () => {
	const block = document.querySelector(".info-slider");
	if (!block) {
		return;
	}
	const slider = block.querySelector(".info-slider__slider");
	const pagination = block.querySelector(".info-slider .swiper-pagination");
	const arrowPrev = block.querySelector(".info-slider__arrow_prev");
	const arrowNext = block.querySelector(".info-slider__arrow_next");

	const swiper = new Swiper(slider, {
		centeredSlides: true,
		loop: true,
		effect: "fade",
		fadeEffect: {
			crossFade: true
		},
		pagination: {
			el: pagination,
			clickable: true,
			dynamicBullets: true
		},
		navigation: {
			nextEl: arrowNext,
			prevEl: arrowPrev
		},
		breakpoints: {
			0: {
				autoHeight: true
			},
			1024: {
				autoHeight: false
			}
		}
	});
};

export default InfoSlider;
