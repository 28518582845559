import Swiper from "swiper/bundle";

const TariffsBox = () => {
	const block = document.querySelector(".tariffs-box");
	if (!block) {
		return;
	}
	const isSimpleView = block.classList.contains("tariffs-box_simple");
	const choose = block.querySelector(".tariffs-box__choose");
	const planToggle = block.querySelector(".tariffs-box__choose-toggle");

	planToggle.addEventListener("click", (e) => {
		const currentPlan = choose.getAttribute("data-plan");

		if (currentPlan === "year") {
			choose.setAttribute("data-plan", "month");
		} else {
			choose.setAttribute("data-plan", "year");
		}
	});

	if (!isSimpleView) {
		// sticky tariffs
		const panel = block.querySelector(".tariffs-box__panel");
		const header = document.querySelector(".header");
		const body = block.querySelector(".tariffs-box__body");

		const panelEl = document.createElement("div");
		panelEl.classList.add("tariffs-box__panel-el");
		body.prepend(panelEl);

		const panelSticky = new StickySidebar(panelEl, {
			containerSelector: ".tariffs-box__body",
			topSpacing: 80,
			bottomSpacing: 200
			// resizeSensor: true
		});

		const showStickyPanel = () => {
			panel.classList.add("is-sticky");
			header.classList.add("is-hidden");
		};

		const hideStickyPanel = () => {
			panel.classList.remove("is-sticky");
			header.classList.remove("is-hidden");
		};

		panelEl.addEventListener("affixed.top.stickySidebar", showStickyPanel);
		panelEl.addEventListener("affixed.static.stickySidebar", hideStickyPanel);
		panelEl.addEventListener("affixed.container-bottom.stickySidebar", hideStickyPanel);


		// mobile version
		const breakpoint = window.matchMedia("(min-width: 768px)");
		let mySwiper;
		const list = block.querySelector(".tariffs-box__list");
		const listCols = list.querySelectorAll(".tariffs-box__col");
		const panelCols = block.querySelectorAll(".tariffs-box__panel-row .tariffs-box__col")

		const handleMobileView = () => {
			const plans = block.querySelector(".tariffs-box__choose-group");
			const plansCol = plans.querySelectorAll(".tariffs-box__col");
			const pagination = block.querySelector(".tariffs-box__choose-dots");
			const arrowPrev = block.querySelector(".tariffs-box__choose-arrow_prev");
			const arrowNext = block.querySelector(".tariffs-box__choose-arrow_next");

			if (!plans.querySelector(".swiper-wrapper")) {
				plansCol.forEach((col) => {
					col.classList.add("swiper-slide");
				});
				const plansInnerConent = plans.innerHTML;
				plans.innerHTML = `<div class="swiper-wrapper">${plansInnerConent}</div>`;
			}
			listCols[1].classList.add("active");
			panelCols[1].classList.add("active");

			mySwiper = new Swiper(plans, {
				slidesPerView: 1,
				spaceBetween: 8,
				pagination: {
					el: pagination,
					clickable: true
				},
				navigation: {
					nextEl: arrowNext,
					prevEl: arrowPrev
				},
				on: {
					slideChange(item) {
						listCols.forEach((col) => {
							col.classList.remove("active");
						});
						panelCols.forEach((col) => {
							col.classList.remove("active");
						});
						listCols[item.activeIndex + 1].classList.add("active");
						panelCols[item.activeIndex + 1].classList.add("active");
					}
				}
			});
			panelSticky.updateSticky();
		};

		const breakpointChecker = function() {
			if (breakpoint.matches === true) {
				if (mySwiper !== undefined) {
					mySwiper.destroy(true, true);

					listCols.forEach((col) => {
						col.classList.remove("active");
					});
					panelCols.forEach((col) => {
						col.classList.remove("active");
					});
				}
				return;
			} else if (breakpoint.matches === false) {
				return handleMobileView();
			}
		};

		breakpoint.addListener(breakpointChecker);
		breakpointChecker();
	}
};

export default TariffsBox;
