import Swiper from "swiper/bundle";
import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const BenefitsBox = () => {
	const block = document.querySelectorAll(".benefits-box");
	if (!block) {
		return;
	}
	block.forEach((element) => {
		const thumbs = Array.from(element.querySelectorAll(".benefits-box__thumbs-item"));
		const progressCircles = Array.from(element.querySelectorAll(".benefits-box__thumbs-progress svg"));
		let mainSlider = null;
		const autoplayDuration = 10000;
		const mediaItems = Array.from(element.querySelectorAll(".benefits-box__media-item"));

		mainSlider = new Swiper(element.querySelector(".benefits-box__media-slider"), {
			loop: true,
			watchOverflow: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			preventInteractionOnTransition: true,
			effect: "fade",
			fadeEffect: {
				crossFade: true
			},
			autoplay: {
				delay: autoplayDuration
			},
			on: {
				slideChangeTransitionStart(s) {
					thumbs.forEach((el) => {
						el.classList.remove("active");
					});
					thumbs[mainSlider.realIndex].classList.add("active");
				},
				autoplayTimeLeft(s, time, progress) {
					progressCircles[mainSlider ? mainSlider.realIndex : 0].style.setProperty("--progress", 1 - progress);
				},
				autoplayPause(s) {
					progressCircles.forEach((el) => {
						el.style.setProperty("--progress", 0);
					});
				}
			}
		});

		thumbs.forEach((el, index) => {
			el.addEventListener("click", (e) => {
				mainSlider.slideToLoop(index);
			});

			el.append(mediaItems[index].querySelector(".benefits-box__media").cloneNode(true));
		});

		gsap.registerPlugin(ScrollTrigger);
		const wrap = element.querySelector(".benefits-box__wrap");

		const TLBenefits = gsap.timeline({
			scrollTrigger: {
				trigger: wrap,
				start: 'top 90%',
				end: `+=${window.innerHeight / 4}`,
				scrub: true
			}
		});
		TLBenefits.fromTo(wrap,
			{ autoAlpha: 0, y: 50 },
			{ autoAlpha: 1, y: 0, duration: 2, ease: Power4.easeOut }, 0.4);
	});
};

export default BenefitsBox;
